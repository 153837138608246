#flashBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px var(--layout-padding-horizontal);
    // background: white repeating-linear-gradient( 45deg, #EEE, #EEE 5px, #FFF 5px, #FFF 10px );
    background-color: var(--color-status-s);

    color: var(--color-status-p);
    font-size: 16px;
    font-weight: bold;

    h1, a {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
    }

    a {
        font-style: italic;

        &:hover {
            text-decoration: underline;
        }

    }

}
section {

    &:not(:last-of-type){
        margin-bottom: 24px;
    }

    &.column .sectionContainer {
        flex-direction: column;
    }

    &.row .sectionContainer {
        flex-direction: row;
    }

    &.center .sectionContainer {
        justify-content: center;
    }

    &.start .sectionContainer {
        justify-content: flex-start;
    }

    &.end .sectionContainer {
        justify-content: flex-end;
    }

    &.between .sectionContainer {
        justify-content: space-between;
    }

    &.around .sectionContainer {
        justify-content: space-around;
    }

    &.evenly .sectionContainer {
        justify-content: space-evenly;
    }

    &.padding .sectionContainer {
        padding: var(--layout-padding);
    }

    .sectionContainer {
        display: flex;
        align-items: center;
    }

}
header {
    position: sticky;
    top: 0;

    height: 80px;
    background-color: var(--color-layout-header);

    &.fixed {
        box-shadow: var(--color-shadow-s);
    }

    #headerContainer {
        display: flex;
        flex-direction: column;
        height: 100%;

        & > div {
            width: 100%;

            &#headerNav {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;
                padding: 8px 24px 4px;
            }

            &#headerIndicator {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                height: 6px;
                background: linear-gradient(
                    to right,
                    rgba(255, 0, 0, 1) 0%,
                    rgba(255, 154, 0, 1) 10%,
                    rgba(208, 222, 33, 1) 20%,
                    rgba(79, 220, 74, 1) 30%,
                    rgba(63, 218, 216, 1) 40%,
                    rgba(47, 201, 226, 1) 50%,
                    rgba(28, 127, 238, 1) 60%,
                    rgba(95, 21, 242, 1) 70%,
                    rgba(186, 12, 248, 1) 80%,
                    rgba(251, 7, 217, 1) 90%,
                    rgba(255, 0, 0, 1) 100%
                );

                #headerIndicatorBarMask {
                    height: 100%;
                    background-color: var(--color-layout-header);
                }

            }

        }

    }

}
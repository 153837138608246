:root {
    /* Theme */
    --color-theme-p: #ffffff;
    --color-theme-p-light: #ffffff;
    --color-theme-p-dark: #ffffff;
    --color-theme-p-hover: #ffffff;

    --color-theme-s: #ffffff;
    --color-theme-s-light: #ffffff;
    --color-theme-s-dark: #ffffff;
    --color-theme-s-hover: #ffffff;

    --color-theme-t: #ffffff;
    --color-theme-t-light: #ffffff;
    --color-theme-t-dark: #ffffff;
    --color-theme-t-hover: #ffffff;

    /* Layout */
    --color-shadow-p: 0px 5px 10px 2px rgba(0,0,0,0.4);
    --color-shadow-s: 0px 2px 6px 2px rgba(0,0,0,0.2);

    /* Light Mode */
    --color-text-p-lm: #202124;
    --color-text-s-lm: #414549;
    --color-text-t-lm: #5f6368;

    --color-layout-p-lm: #ffffff;
    --color-layout-s-lm: #dcdcdc;
    --color-layout-t-lm: #a9a9a9;
    --color-layout-header-lm: #ffffff;
    --color-layout-foreground-lm: #E3E0E0;
    --color-layout-box-lm: #ffffff;

    --color-action-active-lm: rgba(0,0,0,0.54);
    --color-action-hover-lm: rgba(0,0,0,0.14);
    --color-action-selected-lm: rgba(0,0,0,0.08);

    --color-disabled-p-lm: rgba(0,0,0,0.26);
    --color-disabled-s-lm: rgba(0,0,0,0.12);

    --color-divider-lm: rgba(0,0,0,0.12);

    /* Dark Mode */
    --color-text-p-dm: rgb(255,255,255);
    --color-text-s-dm: rgba(255,255,255,0.7);
    --color-text-t-dm: rgba(255,255,255,0.5);

    --color-layout-p-dm: #202124;
    --color-layout-s-dm: #303134;
    --color-layout-t-dm: #1E1E1E;
    --color-layout-header-dm: var(--color-layout-s-dm);
    --color-layout-foreground-dm: var(--color-layout-p-dm);
    --color-layout-box-dm: var(--color-layout-s-dm);

    --color-action-active-dm: rgba(255,255,255,1);
    --color-action-hover-dm: rgba(255,255,255,0.2);
    --color-action-selected-dm: rgba(255,255,255,0.16);

    --color-disabled-p-dm: rgba(255,255,255,0.3);
    --color-disabled-s-dm: rgba(255,255,255,0.12);

    --color-divider-dm: rgba(255,255,255,0.12);

    /* Status */
    --color-status-error-p: #f44336;
    --color-status-error-s: #EABBBB;
    --color-status-error-t: #d32f2f;

    --color-status-warning-p: #ff9800;
    --color-status-warning-s: #FFE694;
    --color-status-warning-t: #f57c00; 

    --color-status-info-p: #2196f3;
    --color-status-info-s: #AFD6F7;
    --color-status-info-t: #1976d2;

    --color-status-success-p: #4caf50;
    --color-status-success-s: #b3ffae;
    --color-status-success-t: #388e3c;

    --color-status-disabled-p: var(--color-disabled-p);
    --color-status-disabled-s: var(--color-disabled-p);
    --color-status-disabled-t: var(--color-disabled-p);

    /* Colors */
    --blue-p: #007bff;
    --blue-s: #a0daff;
    --blue-t: #0050cb;

    --indigo-p: #6610f2;
    --indigo-s: #d77eff;
    --indigo-t: #470ba9;

    --purple-p: #6f42c1;
    --purple-s: #d69fff;
    --purple-t: #3b1590;

    --pink-p: #e83e8c;
    --pink-s: #ffa8ee;
    --pink-t: #b1005f;

    --red-p: #dc3545;
    --red-s: #ff9d9e;
    --red-t: #a3001e;

    --orange-p: #fd7e14;
    --orange-s: #ffe17b;
    --orange-t: #c34f00;

    --yellow-p: #ffc107;
    --yellow-s: #ffff84;
    --yellow-t: #c79100;

    --green-p: #28a745;
    --green-s: #99ffa3;
    --green-t: #007717;

    --teal-p: #20c997;
    --teal-s: #9ffffb;
    --teal-t: #009769;

    --cyan-p: #17a2b8;
    --cyan-s: #98ffff;
    --cyan-t: #007388;

    --brown-p: #795548;
    --brown-s: #a98274;
    --brown-t: #4b2c20;

    --white-p: #ffffff;
    --gray-p: #6c757d;
    --black-p: #000000;

    /* Transitions */
    --transition-default: all 0.1s ease-in-out;
    --transition-theme: border-color 0.1s ease-in-out, background-color 0.1s ease-in-out, color 0.1s ease-in-out, fill 0.1s ease-in-out;

    /* Layout */
    --layout-padding: 24px;
    --layout-padding-horizontal: 24px;
}

.light-mode {
    --color-text-p: var(--color-text-p-lm);
    --color-text-s: var(--color-text-s-lm);
    --color-text-t: var(--color-text-t-lm);

    --color-layout-p: var(--color-layout-p-lm);
    --color-layout-s: var(--color-layout-s-lm);
    --color-layout-t: var(--color-layout-t-lm);
    --color-layout-header: var(--color-layout-header-lm);
    --color-layout-foreground: var(--color-layout-foreground-lm);
    --color-layout-box: var(--color-layout-box-lm);

    --color-action-active: var(--color-action-active-lm);
    --color-action-hover: var(--color-action-hover-lm);
    --color-action-selected: var(--color-action-selected-lm);

    --color-disabled-p: var(--color-disabled-p-lm);
    --color-disabled-s: var(--color-disabled-s-lm);

    --color-divider: var(--color-divider-lm);
}

.dark-mode {
    --color-text-p: var(--color-text-p-dm);
    --color-text-s: var(--color-text-s-dm);
    --color-text-t: var(--color-text-t-dm);

    --color-layout-p: var(--color-layout-p-dm);
    --color-layout-s: var(--color-layout-s-dm);
    --color-layout-t: var(--color-layout-t-dm);
    --color-layout-header: var(--color-layout-header-dm);
    --color-layout-foreground: var(--color-layout-foreground-dm);
    --color-layout-box: var(--color-layout-box-dm);

    --color-action-active: var(--color-action-active-dm);
    --color-action-hover: var(--color-action-hover-dm);
    --color-action-selected: var(--color-action-selected-dm);

    --color-disabled-p: var(--color-disabled-p-dm);
    --color-disabled-s: var(--color-disabled-s-dm);

    --color-divider: var(--color-divider-dm);
}

.blue {
    --color-theme-colors-p: var(--blue-p);
    --color-theme-colors-s: var(--blue-s);
    --color-theme-colors-t: var(--blue-t);
}

.indigo {
    --color-theme-colors-p: var(--indigo-p);
    --color-theme-colors-s: var(--indigo-s);
    --color-theme-colors-t: var(--indigo-t);
}

.purple {
    --color-theme-colors-p: var(--purple-p);
    --color-theme-colors-s: var(--purple-s);
    --color-theme-colors-t: var(--purple-t);
}

.pink {
    --color-theme-colors-p: var(--pink-p);
    --color-theme-colors-s: var(--pink-s);
    --color-theme-colors-t: var(--pink-t);
}

.red {
    --color-theme-colors-p: var(--red-p);
    --color-theme-colors-s: var(--red-s);
    --color-theme-colors-t: var(--red-t);
}

.orange {
    --color-theme-colors-p: var(--orange-p);
    --color-theme-colors-s: var(--orange-s);
    --color-theme-colors-t: var(--orange-t);
}

.yellow {
    --color-theme-colors-p: var(--yellow-p);
    --color-theme-colors-s: var(--yellow-s);
    --color-theme-colors-t: var(--yellow-t);
}

.green {
    --color-theme-colors-p: var(--green-p);
    --color-theme-colors-s: var(--green-s);
    --color-theme-colors-t: var(--green-t);
}

.teal {
    --color-theme-colors-p: var(--teal-p);
    --color-theme-colors-s: var(--teal-s);
    --color-theme-colors-t: var(--teal-t);
}

.cyan {
    --color-theme-colors-p: var(--cyan-p);
    --color-theme-colors-s: var(--cyan-s);
    --color-theme-colors-t: var(--cyan-t);
}

.brown {
    --color-theme-colors-p: var(--brown-p);
    --color-theme-colors-s: var(--brown-s);
    --color-theme-colors-t: var(--brown-t);
}

.status {

    &.error {
        --color-status-p: var(--color-status-error-p);
        --color-status-s: var(--color-status-error-s);
        --color-status-t: var(--color-status-error-t);
    }
    
    &.warning {
        --color-status-p: var(--color-status-warning-p);
        --color-status-s: var(--color-status-warning-s);
        --color-status-t: var(--color-status-warning-t);
    }
    
    &.info {
        --color-status-p: var(--color-status-info-p);
        --color-status-s: var(--color-status-info-s);
        --color-status-t: var(--color-status-info-t);
    }
    
    &.success {
        --color-status-p: var(--color-status-success-p);
        --color-status-s: var(--color-status-success-s);
        --color-status-t: var(--color-status-success-t);
    }

    &.disabled {
        --color-status-p: var(--color-status-disabled-p);
        --color-status-s: var(--color-status-disabled-s);
        --color-status-t: var(--color-status-disabled-t);
    }

}
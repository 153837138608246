#wrapper {
    position: relative;
    min-height: 100vh;

    &.default {
        display: flex;
        flex-direction: column;
    }

    &.center, &.error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh !important;
        padding: var(--layout-padding);
    }

    &.error {
        padding: 48px;
    }

    main {

        #mainContainer {
            height: 2000px;
        }

    }

}